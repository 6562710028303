import React from "react";
import { useForm } from "react-hook-form";

import ImageUploader from "../../components/ImageUploader";

const ProductForm = ({
  defaultValues,
  onSubmit,
  loading = false,
}: {
  defaultValues?: Record<string, any>;
  onSubmit?: (product: Record<string, any>) => void;
  loading?: boolean;
}) => {
  // const [loading, setLoading] = useState(false);
  // const { id } = useParams<{ id?: string | undefined }>();

  // const { name, price, shortDescription, description } = product;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          name: "",
          shortDescription: "",
          description: "",
          price: 0,
        },
  });

  const onHandleSubmit = onSubmit
    ? onSubmit
    : (product: Record<string, any>) => {
        console.log(product);
      };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="form-group">
        <label htmlFor="name">Название</label>
        <input
          id="name"
          type="text"
          className="form-control"
          aria-describedby="emailHelp"
          placeholder="Введите название"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <small id="emailHelp" className="form-text text-muted">
            Это поле обязательное.
          </small>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="shortDescription">Короткое описание</label>
        <input
          id="shortDescription"
          type="text"
          className="form-control"
          aria-describedby="emailHelp"
          placeholder="Введите короткое описание"
          {...register("shortDescription", { required: true })}
        />
        {errors.shortDescription && (
          <small id="emailHelp" className="form-text text-muted">
            Это поле обязательное.
          </small>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="description">Описание</label>
        <textarea
          id="description"
          rows={3}
          className="form-control"
          aria-describedby="emailHelp"
          placeholder="Введите описание"
          {...register("description", { required: true })}
        />
        {errors.description && (
          <small id="emailHelp" className="form-text text-muted">
            Это поле обязательное.
          </small>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="price">Цена</label>
        <input
          id="price"
          type="number"
          min={0}
          className="form-control"
          aria-describedby="emailHelp"
          placeholder="Введите цену"
          {...register("price", { required: true, min: 0 })}
        />
        {errors.price && (
          <small id="emailHelp" className="form-text text-muted">
            Это поле обязательное.
          </small>
        )}
      </div>

      <div className="form-group">
        <ImageUploader />
      </div>

      <div className="d-flex justify-content-end">
        <button type="submit" className="btn primary" disabled={loading}>
          Сохранить
        </button>
      </div>
    </form>
  );
};

export default ProductForm;
