import React from "react";
import { Link } from "react-router-dom";

const AsideHeader = () => (
  <div className="navbar">
    <Link to="/orders" className="navbar-brand mx-auto">
      <span className="hidden-folded d-inline block-center">LikeFish</span>
    </Link>
  </div>
);

export default AsideHeader;
