import React from "react";
import { Link, Route } from "react-router-dom";

import AsideHeader from "../AsideHeader/AsideHeader";
import AsideFooter from "../AsideFooter/AsideFooter";

const AsideMenuLink = ({ label, to, icon /*, activeOnlyWhenExact*/ }: any) => (
  <Route
    path={to}
    exact
    children={({ match }) => (
      <li className={match ? "active" : ""}>
        <Link to={to}>
          <span className="nav-icon">
            <i className={"fa " + icon} />
          </span>
          <span className="nav-text">{label}</span>
        </Link>
      </li>
    )}
  />
);

interface Props {
  isAsideMenuOpen: boolean;
  toggleAsideMenu: () => void;
}

const Aside = ({ isAsideMenuOpen /*, toggleAsideMenu*/ }: Props) => (
  <div
    id="aside"
    className={
      isAsideMenuOpen
        ? "app-aside fade box-shadow-x nav-expand white dark show d-block"
        : "app-aside fade box-shadow-x nav-expand white dark"
    }
    aria-hidden="true"
  >
    <div className="sidenav modal-dialog dk white position-fixed">
      <AsideHeader />

      <div className="flex hide-scroll">
        <div className="scroll">
          <div className="nav-border b-primary">
            <ul className="nav bg">
              <li className="pb-2 hidden-folded" />

              {/*<AsideMenuLink
                    to="/admin/analytics"
                    label="Аналитика"
                    icon="fa-pie-chart"
                  />*/}
              <AsideMenuLink to="/orders" label="Заказы" icon="fa-archive" />
              <AsideMenuLink
                to="/products"
                label="Продукты"
                icon="fa-list-alt"
              />
              {/*<AsideMenuLink
                    to="/admin/categories"
                    label="Категории"
                    icon="fa-folder"
                  />*/}
              {/*<AsideMenuLink
                    to="/admin/users"
                    label="Сотрудники"
                    icon="fa-users"
                  />*/}

              <li className="pb-2 hidden-folded" />
            </ul>
          </div>
        </div>
      </div>

      <AsideFooter />
    </div>
  </div>
);

export default Aside;
