import React from "react";

const AsideFooter = () => (
  <div className="no-shrink lt hide">
    <div className="nav-fold">
      {/* eslint-disable-next-line */}
      <a className="d-flex p-2-3" data-toggle="dropdown">
        <span className="avatar w-28 grey hide">J</span>
        <img src="../profile-icon.jpg" alt="..." className="w-28 circle" />
      </a>
      <div className="dropdown-menu  w pt-0 mt-2 animate fadeIn">
        <div className="row no-gutters b-b mb-2">
          <div className="col-4 b-r">
            <a href="app.user.html" className="py-2 pt-3 d-block text-center">
              <i className="fa text-md fa-phone-square text-muted"></i>
              <small className="d-block">Call</small>
            </a>
          </div>
          <div className="col-4 b-r">
            <a
              href="app.message.html"
              className="py-2 pt-3 d-block text-center"
            >
              <i className="fa text-md fa-comment text-muted"></i>
              <small className="d-block">Chat</small>
            </a>
          </div>
          <div className="col-4">
            <a href="app.inbox.html" className="py-2 pt-3 d-block text-center">
              <i className="fa text-md fa-envelope text-muted"></i>
              <small className="d-block">Email</small>
            </a>
          </div>
        </div>
        <a className="dropdown-item" href="profile.html">
          <span>Profile</span>
        </a>
        <a className="dropdown-item" href="setting.html">
          <span>Settings</span>
        </a>
        <a className="dropdown-item" href="app.inbox.html">
          <span className="float-right">
            <span className="badge info">6</span>
          </span>
          <span>Inbox</span>
        </a>
        <a className="dropdown-item" href="app.message.html">
          <span>Message</span>
        </a>
        <div className="dropdown-divider"></div>
        <a className="dropdown-item" href="docs.html">
          Need help?
        </a>
        <a className="dropdown-item" href="signin.html">
          Sign out
        </a>
      </div>
      <div className="hidden-folded flex p-2-3 bg">
        <div className="d-flex p-1">
          <a href="app.inbox.html" className="flex text-nowrap">
            <i className="fa fa-bell text-muted mr-1"></i>
            <span className="badge badge-pill theme">20</span>
          </a>
          <a
            href="lockme.html"
            className="px-2"
            data-toggle="tooltip"
            title="Logout"
          >
            <i className="fa fa-power-off text-muted"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default AsideFooter;
