import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SignUp from "containers/SignUp";
import Login from "containers/Login";
import ForgotPassword from "containers/ForgotPassword";

import ProductList from "containers/ProductList";
import Product from "containers/Product";

import OrderList from "containers/OrderList";

import CategoryList from "containers/CategoryList";

import Layout from "components/Layout";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  // let auth = useAuth();
  // let location = useLocation();

  // if (!auth.user) {
  //   // Redirect them to the /login page, but save the current location they were
  //   // trying to go to when they were redirected. This allows us to send them
  //   // along to that page after they log in, which is a nicer user experience
  //   // than dropping them off on the home page.
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return children;
};

const App = () => (
  <Router>
    <Switch>
      <Route path="/signup" exact>
        <SignUp />
      </Route>
      <Route path="/signin" exact>
        <Login />
      </Route>
      <Route path="/forgot-password" exact>
        <ForgotPassword />
      </Route>

      <Route path="/categories" exact>
        <RequireAuth>
          <Layout>
            <CategoryList />
          </Layout>
        </RequireAuth>
      </Route>

      <Route path="/products" exact>
        <RequireAuth>
          <Layout>
            <ProductList />
          </Layout>
        </RequireAuth>
      </Route>
      <Route path="/products/:id" exact>
        <Layout>
          <Product />
        </Layout>
      </Route>

      <Route path="/" exact>
        <Layout>
          <OrderList />
        </Layout>
      </Route>

      <Route path="/orders" exact>
        <Layout>
          <OrderList />
        </Layout>
      </Route>
    </Switch>
  </Router>
);

export default App;
