import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import config from "config";
import useJwt from "hooks/useJwt";
import AuthHeader from "components/AuthHeader";

interface TSignUp {
  email: string;
  password: string;
}

interface Props {
  defaultValues?: TSignUp;
}

const SignUp = ({ defaultValues }: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { setJwtToken } = useJwt();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          email: "test@mail.com",
          password: "password",
        },
  });

  const onSubmit = (authCredentials: TSignUp) => {
    setLoading(true);

    const url = `${config.ApiUrl}/signup`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authCredentials),
    })
      .then((response) => response.json())
      .then((token) => {
        // Set token
        setJwtToken(token);
        // Redirect
        history.push("/orders");
      })
      .catch((error) => {
        console.error("error:", error);
      });

    setLoading(false);
  };

  return (
    <div className="d-flex flex-column flex">
      <AuthHeader />
      <div id="content-body">
        <div className="py-5 text-center w-100">
          <div className="mx-auto w-xxl w-auto-xs">
            <div className="px-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    className="form-control"
                    placeholder="E-mail"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <small id="emailHelp" className="form-text text-muted">
                      Это поле обязательное.
                    </small>
                  )}
                </div>

                <div className="form-group">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    placeholder="Пароль"
                    {...register("password", { required: true })}
                  />
                  {errors.password && (
                    <small id="emailHelp" className="form-text text-muted">
                      Это поле обязательное.
                    </small>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-block primary"
                  disabled={loading}
                >
                  Зарегистрироваться
                </button>
              </form>

              <div className="py-4 text-center">
                <div>
                  Уже есть аккаунт?{" "}
                  <a
                    onClick={() => history.push("/login")}
                    className="text-primary _600"
                  >
                    Войти
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
