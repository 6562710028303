import React, { useState } from "react";

import Aside from "../Aside/Aside";
import Content from "../Content/Content";
import ModalBackdrop from "../ModalBackdrop/ModalBackdrop";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const [isAsideMenuOpen, setIsAsideMenuOpen] = useState(false);

  const toggleAsideMenu = () => {
    setIsAsideMenuOpen(!isAsideMenuOpen);
  };

  return (
    <div className="app" id="app">
      <Aside
        isAsideMenuOpen={isAsideMenuOpen}
        toggleAsideMenu={toggleAsideMenu}
      />
      <Content toggleAsideMenu={toggleAsideMenu}>{children}</Content>
      <ModalBackdrop
        isAsideMenuOpen={isAsideMenuOpen}
        toggleAsideMenu={toggleAsideMenu}
      />
    </div>
  );
};

export default Layout;
