import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import AuthHeader from "components/AuthHeader";

interface Props {
  defaultValues?: Record<string, any>;
  onSubmit?: (product: Record<string, any>) => void;
}

const Login = ({ defaultValues, onSubmit }: Props) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          email: "",
          password: "",
        },
  });

  const onHandleSubmit = onSubmit
    ? onSubmit
    : (product: Record<string, any>) => {
        console.log(product);
      };

  return (
    <div className="d-flex flex-column flex">
      <AuthHeader />
      <div id="content-body">
        <div className="py-5 text-center w-100">
          <div className="mx-auto w-xxl w-auto-xs">
            <div className="px-3">
              <form onSubmit={handleSubmit(onHandleSubmit)}>
                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    className="form-control"
                    placeholder="E-mail"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <small id="emailHelp" className="form-text text-muted">
                      Это поле обязательное.
                    </small>
                  )}
                </div>

                <div className="form-group">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    placeholder="Пароль"
                    {...register("password", { required: true })}
                  />
                  {errors.password && (
                    <small id="emailHelp" className="form-text text-muted">
                      Это поле обязательное.
                    </small>
                  )}
                </div>

                <button type="submit" className="btn btn-block primary">
                  Войти
                </button>
              </form>
              <div className="my-4">
                <a
                  onClick={() => history.push("/forgot-password")}
                  className="text-primary _600"
                >
                  Забыли пароль?
                </a>
              </div>
              <div>
                У вас нет аккаунта?{" "}
                <a
                  onClick={() => history.push("/signup")}
                  className="text-primary _600"
                >
                  Зарегистрироваться
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
