import React from "react";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";

const ForgotPassword = () => {
  const history = useHistory();
  return (
    <div className="d-flex flex-column flex">
      <AuthHeader />
      <div id="content-body">
        <div className="py-5 text-center w-100">
          <div className="mx-auto w-xxl w-auto-xs">
            <div className="px-3">
              <div>
                <h5>Забыли пароль?</h5>
                <p className="text-muted my-3">
                  Введи ваш имейл ниже и мы отправим вам инструкции как сбросить
                  пароль.
                </p>
              </div>
              <form name="reset">
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="E-mail"
                    className="form-control"
                    required
                  />
                </div>
                <button type="submit" className="btn primary btn-block p-x-md">
                  Отправить
                </button>
              </form>
              <div className="py-4">
                Вернуться на страницу{" "}
                <a
                  onClick={() => history.push("/login")}
                  className="text-primary _600"
                >
                  Войти
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
