import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const ImageUploader = (props: any) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        // @ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );

      // Upload to S3
      const formData = new FormData();

      console.log(acceptedFiles[0]);

      formData.append("file", acceptedFiles[0]);

      const url = "http://localhost:8080/file/upload";

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Success:", result);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    maxFiles: 2,
  });

  const thumbs = files.map((file: any) => (
    // @ts-ignore
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="Product image" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <section
    // className="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center dz-clickable" /*className="container"*/
    >
      <label htmlFor="price">Изображение</label>

      <div
        {...getRootProps({
          className:
            "dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center dz-clickable",
        })}
      >
        <input multiple={false} {...getInputProps()} />
        {/*<p className="mb-0">
          Drag 'n' drop some files here, or click to select files
        </p>*/}
        <p className="mb-0">
          Перетащите сюда файл или кликните чтобы выбрать файл
        </p>
      </div>
      {/* @ts-ignore*/}
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
};

export default ImageUploader;
