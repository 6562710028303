import React from "react";
import { useHistory } from "react-router-dom";

const AuthHeader = () => {
  const history = useHistory();
  return (
    <div className="navbar light bg pos-rlt box-shadow">
      <div className="mx-auto">
        <a onClick={() => history.push("/")} className="navbar-brand">
          <span className="hidden-folded d-inline">Delivo: Админ панель</span>
        </a>
      </div>
    </div>
  );
};

export default AuthHeader;
