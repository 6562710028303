import { useState } from "react";

const useJwt = () => {
  const [jwt, setJwt] = useState(() => {
    try {
      const token = window.localStorage.getItem("token");
      return token ? JSON.parse(token) : "";
    } catch (error) {
      console.log(error);

      return "";
    }
  });

  const setJwtToken = (token: string) => {
    try {
      setJwt(token);
      window.localStorage.setItem("token", JSON.stringify(token));
    } catch (error) {
      console.log(error);
    }
  };

  const removeJwtToken = () => {
    try {
      setJwt("");
      window.localStorage.setItem("token", JSON.stringify(""));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    jwtToken: jwt,
    setJwtToken,
    removeJwtToken,
  };
};

export default useJwt;
