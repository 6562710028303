import React from "react";

interface Props {
  isAsideMenuOpen: boolean;
  toggleAsideMenu: () => void;
}

const ModalBackdrop = ({ isAsideMenuOpen, toggleAsideMenu }: Props) => (
  <div
    className={
      isAsideMenuOpen ? "modal-backdrop fade show" : "modal-backdrop fade hide"
    }
    onClick={toggleAsideMenu}
  />
);

export default ModalBackdrop;
