import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useJwt from "hooks/useJwt";
import { useJwt as useJwtLib } from "react-jwt";

const SettingsLink = () => (
  <Link className="dropdown-item mt-2" to="/settings">
    <span>Settings</span>
  </Link>
);

interface Props {
  toggleAsideMenu: () => void;
}

const ContentHeader = ({ toggleAsideMenu }: Props) => {
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { jwtToken } = useJwt();
  const { decodedToken } = useJwtLib(jwtToken);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const user = {
    name: "",
    surname: "",
    email: "",
    ...decodedToken,
  };

  return (
    <div className="content-header white  box-shadow-4" id="content-header">
      <div className="navbar navbar-expand-lg">
        <a onClick={toggleAsideMenu} className="d-lg-none mx-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 512 512"
          >
            <path d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z" />
          </svg>
        </a>
        <div className="navbar-text nav-title flex" />
        <ul className="nav flex-row order-lg-2 position-relative">
          <li className="d-flex align-items-center">
            <a
              onClick={toggleMenu}
              data-toggle="dropdown"
              className="d-flex align-items-center"
              aria-expanded="false"
            >
              <span className="mx-2 d-none l-h-1x d-lg-block">
                {/*<span>{user.name + " " + user.surname}</span>*/}
                <span>{user.email}</span>
              </span>
            </a>

            <div
              className={`dropdown-menu dropdown-menu-right w pt-0 mt-3 animate fadeIn ${
                isMenuOpen ? "show" : ""
              }`}
            >
              <SettingsLink />
              <div className="dropdown-divider" />
              <a
                className="dropdown-item"
                onClick={() => history.push("/register")}
              >
                Sign out
              </a>
            </div>
          </li>

          <li className="d-lg-none d-flex align-items-center">
            <a onClick={toggleMenu} className="mx-2" data-toggle="collapse">
              {/*{user.name + " " + user.surname}*/}
              {user.email}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContentHeader;
