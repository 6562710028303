import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";
import { Box, Modal } from "@mui/material";
import ProductForm from "containers/Product/components/Form";
import config from "config";
import { Product } from "types";

const style = {
  position: "absolute", // as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  p: 4,
};

const ProductList = () => {
  const [products, setProducts] = useState<Array<Product>>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: "Название",
        accessor: "name",
      },
      {
        Header: "Короткое описание",
        accessor: "description_short",
      },
      {
        Header: "Описание",
        accessor: "description",
      },
      {
        Header: "Цена",
        accessor: "price",
      },
      {
        Header: "",
        accessor: "actions",
        customWidth: 120,
        Cell: (cell: any) => (
          <div style={{ width: 110 }}>
            <React.Fragment>
              <button
                className="btn btn-icon white btn-sm-actions"
                title="Click to show the note"
                onClick={() => {
                  history.push(`/products/${cell.row.id}`);
                }}
              >
                <i className="fa fa-eye text-success" />
              </button>
              <button
                className="btn btn-icon white btn-sm-actions"
                title="Click to edit the note"
                onClick={() => {}}
              >
                <i className="fa fa-pencil text-warning" />
              </button>
              <button
                className="btn btn-icon white btn-sm-actions"
                title="Click to delete the note"
                onClick={() => {}}
              >
                <i className="fa fa-times text-danger" />
              </button>
            </React.Fragment>
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      // @ts-ignore
      // products,
      data: products,
    });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (product: Record<string, any>) => {
    setLoading(true);

    const url = `${config.ApiUrl}/products`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...product, price: Number(product.price) }),
    })
      .then((response) => response.json())
      .then((result) => {
        setProducts([...products, result]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setLoading(false);
  };

  const getProducts = () => {
    const url = `${config.ApiUrl}/products`;

    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then((result) => {
        // console.log("products:", result);
        setProducts(result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <div className="padding">
        <div className="box">
          <div className="box-header">
            <div className="d-flex justify-content-between">
              <h4
                className="d-inline-block"
                style={{
                  fontSize: "27px",
                  marginTop: "3px",
                }}
              >
                Продукты
              </h4>
              {/*<input type="text" style={{width: '200px'}} className="form-control" placeholder="Search"/>*/}
              <div className="d-flex align-items-center">
                {/*<TableSearchInput
                        {...props.searchProps}
                        type="text"
                        style={{width: '250px', marginRight: '10px'}}
                        className="form-control d-none d-md-block"
                        placeholder="Search"
                      />*/}
                <button
                  className="btn btn-fw success box-shadow-4"
                  style={{
                    boxShadow: "none",
                    color: "#fff",
                  }}
                  // onClick={this.toggleModalNoteCreate}
                  onClick={handleOpen}
                >
                  Добавить продукт
                </button>
              </div>
            </div>

            {/*<TableSearchInput
                    {...props.searchProps}
                    type="text"
                    style={{width: '100%'}}
                    className="form-control mt-2 d-md-none"
                    placeholder="Search"
                  />*/}
          </div>
          <table className="table table-striped b-t" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{ width: column.customWidth }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: any) => {
                prepareRow(row);
                return (
                  <tr key={i} {...row.getRowProps()}>
                    {row.cells.map((cell: any, i: number) => {
                      return (
                        <td
                          key={i}
                          // onClick={() => history.push("/admin/products/1")}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* @ts-ignore */}
        <Box sx={style}>
          <div className="modal-content box-shadow-md mb-3">
            <div className="modal-header">
              <h5 className="modal-title">Добавить продукт</h5>
            </div>
            <div className="modal-body">
              <ProductForm onSubmit={onSubmit} loading={loading} />
            </div>
            {/*<div className="modal-footer">
              <button className="btn dark-white">Отменить</button>
              <button
                className="btn primary"
                // style="box-shadow: none; color: rgb(255, 255, 255);"
                // onClick={onSubmit}
              >
                Добавить
              </button>
            </div>*/}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProductList;
