import React from "react";
import ProductForm from "./components/Form";
import { Product as ProductType } from "../../types";

const productExample: ProductType = {
  id: 6,
  name: "Product #6",
  shortDescription: "8 кусочков, Вес 280 г",
  description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
  price: 100.0,
};

const Product = () => {
  // const [loading, setLoading] = useState(false);
  // const { id } = useParams<{ id?: string | undefined }>();

  // const { name, price, shortDescription, description } = product;

  // TODO Load product

  const onSubmit = (product: Record<string, any>) => {
    console.log(product);
  };

  return (
    <div className="padding">
      <div className="row">
        <div className="col-md-6">
          <div className="box">
            <div className="box-body">
              <ProductForm
                defaultValues={productExample}
                onSubmit={onSubmit}
                loading={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
