import React from "react";

import ContentHeader from "../ContentHeader/ContentHeader";
import ContentMain from "../ContentMain/ContentMain";

interface Props {
  children: React.ReactNode;
  toggleAsideMenu: () => void;
}

const Content = ({ children, toggleAsideMenu }: Props) => (
  <div
    id="content"
    className="app-content box-shadow-1 box-radius-1"
    role="main"
  >
    <ContentHeader toggleAsideMenu={toggleAsideMenu} />

    <ContentMain>{children}</ContentMain>
  </div>
);

export default Content;
